import React, { useEffect, useState } from "react";
import { Collapse, Form, InputGroup, Modal, Offcanvas } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import { FaTrashCan } from "react-icons/fa6";
import Multiselect from "multiselect-react-dropdown";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const MergeAppendTools = ({
  Show,
  Hide,
  studyId,
  crfDatasets,
  derivationId,
  derivationData,
  type,
  getDomainDerivationList,
  derivedData,
}) => {
  let [primaryKeys, setPrimaryKeys] = useState([]);
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [statements, setStatements] = useState([]);
  let [statementString, setStatementString] = useState("");
  let [primaryDataType, setPrimaryDataType] = useState("");
  let [primaryDataset, setPrimaryDataset] = useState("");
  let [secondaryDataType, setSecondaryDataType] = useState("");
  let [secondaryDataset, setSecondaryDataset] = useState("");
  let [procedureType, setProcedureType] = useState("");
  let [label, setLabel] = useState("");
  let [editStatement, setEditStatement] = useState(false);
  let [selectedOptions, setSelectedOptions] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.primaryDataType = primaryDataType;
    userData.primaryDataset = primaryDataset;
    userData.secondaryDataType = secondaryDataType;
    userData.secondaryDataset = secondaryDataset;
    if (statements?.length > 0) {
      userData.statements = statements;
    }
    userData.primaryKey = selectedOptions?.map((item) => item.value);
    userData.label = label;
    userData.procedureType = type === "Append" ? "append" : procedureType;
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
    conditionString()
    Hide();
  };

  const updateTemplateId = (templateId) => {
    let checkId = templateIds?.some((item) => item.templateId === templateId);
    if (checkId) {
      setTemplateIds((preDerivation) =>
        preDerivation.map((item) =>
          item.templateId === templateId
            ? {
              ...item,
              templateId: templateId,
            }
            : item
        )
      );
    } else {
      if (templateId) {
        setTemplateIds((prevItems) => [
          ...prevItems,
          { templateId: templateId },
        ]);
      }
    }
  };

  const conditionString = () => {
    let result = '';
    statements?.forEach(item => {
      if (item.type === 'if') {
        const keys = item.keys.map(k => k.label).join("', '");
        result += `IF ${item.keyType} = ['${keys}'] \n`;
      } else if (item.type === 'elseif') {
        const keys = item.keys.map(k => k.label).join("', '");
        result += `ELSEIF ${item.keyType} = ['${keys}'] \n`;
      } else if (item.type === 'then') {
        result += `THENDO ${item.columnName} = '${item.value}' \n`;
      } else if (item.type === 'else') {
        result += `ELSE ${item.columnName} = '${item.value}'\n`;
      } else if (item.type === 'end') {
        result += `ENDDO \n`;
      }
    });
    setStatementString(result)
  }

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
    setPrimaryKeys(data?.data || []);
  };

  useEffect(() => {
    setPrimaryDataType(derivationData?.primaryDataType);
    setPrimaryDataset(derivationData?.primaryDataset);
    setSecondaryDataType(derivationData?.secondaryDataType);
    setSecondaryDataset(derivationData?.secondaryDataset);
    let primaryKey = derivationData?.primaryKey || [];
    setSelectedOptions(
      primaryKey?.map((item) => ({
        label: item,
        value: item,
      }))
    );
    setProcedureType(derivationData?.procedureType);
    setLabel(derivationData?.label);
    setStatements(derivationData?.statements)
  }, [derivationData]);

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  useEffect(() => {
    if (statements?.length > 0) {
      conditionString()
    }
  }, [statements]);

  const addNewStatement = (type) => {
    const newObject = {
      id: Math.random().toString().substr(2, 10),
      type: type,
    };

    if (type === "if" || type === "elseif") {
      newObject.keyType = "";
      newObject.keys = [];
      newObject.key = "";
      newObject.operator = "";
      newObject.value = "";
    }

    if (type === "else" || type === "then") {
      newObject.columnType = "";
      newObject.columnName = "";
      newObject.value = "";
    }

    setStatements((prevItems) => [...prevItems, newObject]);
  };

  const handleStatementChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const handleMultiSelectChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const removeStatement = (id) => {
    let newArray = statements?.filter((item) => item?.id !== id);
    setStatements(newArray);
  };

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 550 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Configuration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto py-2 px-3"
            style={{ height: "calc(100vh - 87px" }}
          >
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 fw-bold">
                Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </Form.Group>
            <div
              className="py-2 px-3 mb-2 rounded position-relative"
              style={{ border: "1px dashed #dee2e6" }}
            >
              <Form.Label
                className="m-0 fw-bold position-absolute"
                style={{ top: -12, left: 10 }}
              >
                Dataset Configuration
              </Form.Label>
              <div className="hstack gap-2">
                <Form.Group className="mb-2 w-100">
                  <Form.Label className="mb-1 fw-bold">
                    Primary Dataset <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="mb-1"
                    onChange={(e) => setPrimaryDataType(e.target.value)}
                  >
                    <option value="">Select Dataset Type</option>
                    <option selected={primaryDataType === "crf"} value="crf">
                      CRF Datasets
                    </option>
                    <option
                      selected={primaryDataType === "derivation"}
                      value="derivation"
                    >
                      Derivation Datasets
                    </option>
                  </Form.Select>
                  {primaryDataType === "crf" && (
                    <Form.Select
                      onChange={(e) => {
                        setPrimaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select CRF Data</option>
                      {crfDatasets?.map((item, index) => (
                        <option
                          selected={primaryDataset === item.uniqueId}
                          key={index}
                          value={item?.uniqueId}
                        >
                          {item.datasetName}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  {primaryDataType === "derivation" && (
                    <Form.Select
                      onChange={(e) => {
                        setPrimaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select Derivation Data</option>
                      {derivedData?.map(
                        (item, index) =>
                          item.logStatus === 200 && (
                            <option
                              selected={primaryDataset === item.id}
                              key={index}
                              value={item?.id}
                            >
                              {item.label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  )}
                </Form.Group>
                <Form.Group className="mb-2 w-100">
                  <Form.Label className="mb-1 fw-bold">
                    Secondary Dataset <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="mb-1"
                    onChange={(e) => setSecondaryDataType(e.target.value)}
                  >
                    <option value="">Select Dataset Type</option>
                    <option selected={secondaryDataType === "crf"} value="crf">
                      CRF Datasets
                    </option>
                    <option
                      selected={secondaryDataType === "derivation"}
                      value="derivation"
                    >
                      Derivation Datasets
                    </option>
                  </Form.Select>
                  {secondaryDataType === "crf" && (
                    <Form.Select
                      onChange={(e) => {
                        setSecondaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select CRF Data</option>
                      {crfDatasets?.map((item, index) => (
                        <option
                          selected={secondaryDataset === item.uniqueId}
                          key={index}
                          value={item?.uniqueId}
                        >
                          {item.datasetName}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  {secondaryDataType === "derivation" && (
                    <Form.Select
                      onChange={(e) => {
                        setSecondaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select Derivation Data</option>
                      {derivedData?.map(
                        (item, index) =>
                          item.logStatus === 200 && (
                            <option
                              selected={secondaryDataset === item.id}
                              key={index}
                              value={item?.id}
                            >
                              {item.label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  )}
                </Form.Group>
              </div>
              <Form.Group className="mb-2 w-100">
                <Form.Label className="mb-1 fw-bold">
                  Primary Key <span className="text-danger">*</span>
                </Form.Label>
                {primaryKeys?.length > 0 ? (
                  <Multiselect
                    displayValue="label"
                    options={primaryKeys.map((keys) => ({
                      label: keys?.name,
                      value: keys?.name,
                    }))}
                    selectedValues={selectedOptions}
                    onSelect={(selectedList, selectedItem) => {
                      setSelectedOptions(selectedList);
                    }}
                    onRemove={(selectedList, removedItem) => {
                      setSelectedOptions(selectedList);
                    }}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    disabled
                    placeholder="Select Primary Key"
                    value={selectedOptions
                      ?.map((item) => item.value)
                      ?.join(", ")}
                  />
                )}
              </Form.Group>
              {type === "Merge" && (
                <Form.Group className="mb-2 w-100">
                  <Form.Label className="mb-1 fw-bold">
                    Merge Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setProcedureType(e.target.value)}
                  >
                    <option value="">Select Merge Type</option>
                    <option selected={procedureType === "inner"} value="inner">
                      Inner Join
                    </option>
                    <option selected={procedureType === "outer"} value="outer">
                      Outer Join
                    </option>
                    <option selected={procedureType === "left"} value="left">
                      Left Join
                    </option>
                    <option selected={procedureType === "right"} value="right">
                      Right Join
                    </option>
                    <option selected={procedureType === "cross"} value="cross">
                      Cross Join
                    </option>
                  </Form.Select>
                </Form.Group>
              )}
            </div>
            <Form.Label
              onClick={conditionString}
              className="mb-2 fw-bold"
            >
              <div className="hstack gap-2">
                Statement (Optional)
                <Form.Check
                  onChange={() => setEditStatement(!editStatement)}
                  checked={editStatement}
                  type="switch"
                  id="custom-switch"
                />
              </div>
            </Form.Label>
            {!editStatement ?
              statementString &&
              <SyntaxHighlighter language="javascript" style={dracula}>
                {statementString}
              </SyntaxHighlighter>
              :
              <div
                className="p-3 rounded"
                style={{ border: "1px dashed #dee2e6" }}
              >
                <div className="hstack gap-2 mb-2">
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("if")}
                  >
                    IF
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("else")}
                  >
                    ELSE
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("elseif")}
                  >
                    ELSE IF
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("then")}
                  >
                    THEN DO
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("end")}
                  >
                    END DO
                  </button>
                </div>
                {statements?.map((item, index) => (
                  <div key={index}>
                    <Collapse in={item?.type === "if"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            IF
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            onChange={(e) => handleStatementChange(item.id, 'keyType', e.target.value)}
                            className="mb-2">
                            <option value="">Select</option>
                            <option selected={item?.keyType === "firstKey"} value="firstKey">First Key</option>
                            <option selected={item?.keyType === "lastKey"} value="lastKey">Last Key</option>
                            <option selected={item?.keyType === "column"} value="column">Column</option>
                          </Form.Select>
                          {item?.keyType !== "column" &&
                            <Multiselect
                              displayValue="label"
                              options={uniqueColumnKey.map((keys, key) => ({
                                label: keys?.name,
                                value: keys?.name,
                              }))}
                              selectedValues={item.keys || []}
                              onSelect={(selectedList) => handleMultiSelectChange(item.id, 'keys', selectedList)}
                              onRemove={(removedList) => handleMultiSelectChange(item.id, 'keys', removedList)}
                            />
                          }
                          {item?.keyType === "column" &&
                            <>
                              <Form.Select
                                onChange={(e) => handleStatementChange(item.id, 'key', e.target.value)}
                                className="mb-2">
                                <option value={""}>{`Select Column`}</option>
                                {uniqueColumnKey?.map((data, index) => (
                                  <option selected={item?.key === data?.name} key={index} value={data?.name}>{data?.name}</option>
                                ))}
                              </Form.Select>
                              <div className="my-2">
                                <InputGroup className="justify-content-center">
                                  <InputGroup.Text
                                    className={`cursor-pointer updateProfile ${item.operator === "AND" && "bg-white text-muted"}`}
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'AND')}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "3px 0 0 3px",
                                    }}
                                  >
                                    AND
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'OR')}
                                    className={`cursor-pointer updateProfile ${item.operator === "OR" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    OR
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '+')}
                                    className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    +
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '-')}
                                    className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    -
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '/')}
                                    className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    /
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '*')}
                                    className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    *
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '%')}
                                    className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    %
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    =
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !=
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '>')}
                                    className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=>')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=>" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    IN
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !IN
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <Form.Control
                                onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)}
                                type="text"
                                value={item.value}
                                placeholder="Enter Value"
                              />
                            </>
                          }
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "elseif"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            ELSEIF
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            onChange={(e) => handleStatementChange(item.id, 'keyType', e.target.value)}
                            className="mb-2">
                            <option value="">Select</option>
                            <option selected={item?.keyType === "firstKey"} value="firstKey">First Key</option>
                            <option selected={item?.keyType === "lastKey"} value="lastKey">Last Key</option>
                            <option selected={item?.keyType === "column"} value="column">Column</option>
                          </Form.Select>
                          {item?.keyType !== "column" &&
                            <Multiselect
                              displayValue="label"
                              options={uniqueColumnKey.map((keys, key) => ({
                                label: keys?.name,
                                value: keys?.name,
                              }))}
                              selectedValues={item.keys || []}
                              onSelect={(selectedList) => handleMultiSelectChange(item.id, 'keys', selectedList)}
                              onRemove={(removedList) => handleMultiSelectChange(item.id, 'keys', removedList)}
                            />
                          }
                          {item?.keyType === "column" &&
                            <>
                              <Form.Select
                                onChange={(e) => handleStatementChange(item.id, 'key', e.target.value)}
                                className="mb-2">
                                <option value={""}>{`Select Column`}</option>
                                {uniqueColumnKey?.map((data, index) => (
                                  <option selected={item?.key === data?.name} key={index} value={data?.name}>{data?.name}</option>
                                ))}
                              </Form.Select>
                              <div className="my-2">
                                <InputGroup className="justify-content-center">
                                  <InputGroup.Text
                                    className={`cursor-pointer updateProfile ${item.operator === "AND" && "bg-white text-muted"}`}
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'AND')}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "3px 0 0 3px",
                                    }}
                                  >
                                    AND
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'OR')}
                                    className={`cursor-pointer updateProfile ${item.operator === "OR" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    OR
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '+')}
                                    className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    +
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '-')}
                                    className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    -
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '/')}
                                    className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    /
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '*')}
                                    className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    *
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '%')}
                                    className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    %
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    =
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !=
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '>')}
                                    className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=>')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=>" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    IN
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !IN
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <Form.Control value={item.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                            </>
                          }
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "then"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            THEN DO
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            className="mb-1"
                            onChange={(e) => handleStatementChange(item.id, 'columnType', e.target.value)}
                          >
                            <option value="">Select Coumn Type</option>
                            <option selected={item?.columnType === "dataset"} value="dataset">From Dataset</option>
                            <option selected={item?.columnType === "custom"} value="custom">Custom</option>
                          </Form.Select>
                          {item.columnType === "dataset" &&
                            <Form.Select
                              className="mb-1"
                              onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)}
                            >
                              <option value="">Select Coumn</option>
                              {uniqueColumnKey?.map((data, index) => (
                                <option selected={item?.columnName === data?.name} key={index} value={data?.name}>{data?.name}</option>
                              ))}
                            </Form.Select>
                          }
                          {item.columnType === "custom" &&
                            <Form.Control value={item?.columnName} type="text" onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)} placeholder="Enter Custom Column Name" />
                          }
                          <div className=" my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: 3,
                                }}
                              >
                                =
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control value={item?.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "else"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            ELSE
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            className="mb-1"
                            onChange={(e) => handleStatementChange(item.id, 'columnType', e.target.value)}
                          >
                            <option value="">Select Coumn Type</option>
                            <option selected={item?.columnType === 'dataset'} value="dataset">From Dataset</option>
                            <option selected={item?.columnType === 'custom'} value="custom">Custom</option>
                          </Form.Select>
                          {item.columnType === "dataset" &&
                            <Form.Select
                              className="mb-1"
                              onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)}
                            >
                              <option value="">Select Coumn</option>
                              {uniqueColumnKey?.map((data, index) => (
                                <option selected={item?.columnName === data?.name} key={index} value={data?.name}>{data?.name}</option>
                              ))}
                            </Form.Select>
                          }
                          {item.columnType === "custom" &&
                            <Form.Control value={item?.columnName} type="text" onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)} placeholder="Enter Custom Column Name" />
                          }
                          <div className=" my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: 3,
                                }}
                              >
                                =
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control value={item?.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "end"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            END DO
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="shadow-sm bg-light text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MergeAppendTools;
