import React, { useEffect, useState } from "react";
import { Col, Collapse, Form, InputGroup, Offcanvas, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FaSquarePlus, FaTrashCan } from "react-icons/fa6";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Multiselect from "multiselect-react-dropdown";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const SortTransposeTools = ({
  Show,
  Hide,
  studyId,
  crfDatasets,
  derivationId,
  type,
  getDomainDerivationList,
  derivedData,
  derivationData,
}) => {
  let [primaryDataType, setPrimaryDataType] = useState("");
  let [statements, setStatements] = useState([]);
  let [statementString, setStatementString] = useState("");
  let [primaryDataset, setPrimaryDataset] = useState("");
  let [prefix, setPrefix] = useState("");
  let [suffix, setSuffix] = useState("");
  let [label, setLabel] = useState("");
  let [columns, setColumns] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [editStatement, setEditStatement] = useState(false);
  const [formSections, setFormSections] = useState([
    { id: 1, column: "", sortType: "" },
  ]);
  const [idVar, setIdVar] = useState([
    { id: 1, column: "" },
  ]);
  const [byVar, setByVar] = useState([
    { id: 1, column: "" },
  ]);
  const [vars, setVars] = useState([
    { id: 1, column: "" },
  ]);

  const handleAddSection = () => {
    if (type === "Sort") {
      setFormSections([
        ...formSections,
        { id: formSections.length + 1, column: "", sortType: "" },
      ]);
    } else {
      setIdVar([
        ...idVar,
        { id: idVar.length + 1, column: "" },
      ]);
    }
  };

  const handleRemoveSection = (id) => {
    if (type === "Sort") {
      const updatedSections = formSections.filter((section) => section.id !== id);
      setFormSections(updatedSections);
    } else {
      const updatedSections = idVar.filter((section) => section.id !== id);
      setIdVar(updatedSections);
    }
  };

  const handleSelectChange = (id, field, value) => {
    if (type === "Sort") {
      const updatedSections = formSections.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      );
      setFormSections(updatedSections);
    } else {
      const updatedSections = idVar.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      );
      setIdVar(updatedSections);
    }
  };

  const handleByVarAddSection = () => {
    setByVar([
      ...byVar,
      { id: byVar.length + 1, column: "" },
    ]);
  };

  const handleByVarRemoveSection = (id) => {
    const updatedSections = byVar.filter((section) => section.id !== id);
    setByVar(updatedSections);
  };

  const handleByVarSelectChange = (id, field, value) => {
    const updatedSections = byVar.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setByVar(updatedSections);
  };

  const handleVarsAddSection = () => {
    setVars([
      ...vars,
      { id: vars.length + 1, column: "" },
    ]);
  };

  const handleVarsRemoveSection = (id) => {
    const updatedSections = vars.filter((section) => section.id !== id);
    setVars(updatedSections);
  };

  const handleVarsSelectChange = (id, field, value) => {
    const updatedSections = vars.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setVars(updatedSections);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    setPrimaryDataset(primaryDataset);
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      primaryDataType || type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.primaryDataType = primaryDataType;
    userData.primaryDataset = primaryDataset;
    if (type === "Sort") {
      userData.sortData = formSections;
    } else {
      userData.idVar = idVar;
      userData.byVar = byVar;
      userData.vars = vars;
      userData.prefix = prefix;
      userData.suffix = suffix;
    }
    userData.label = label;
    if (statements?.length > 0) {
      userData.statements = statements;
    }
    userData.procedureType = type === "Sort" ? "sort" : "transpose";
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
    conditionString()
    Hide();
  };

  const conditionString = () => {
    let result = '';
    statements?.forEach(item => {
      if (item.type === 'if') {
        const keys = item.keys.map(k => k.label).join("', '");
        result += `IF ${item.keyType} = ['${keys}'] \n`;
      } else if (item.type === 'elseif') {
        const keys = item.keys.map(k => k.label).join("', '");
        result += `ELSEIF ${item.keyType} = ['${keys}'] \n`;
      } else if (item.type === 'then') {
        result += `THENDO ${item.columnName} = '${item.value}' \n`;
      } else if (item.type === 'else') {
        result += `ELSE ${item.columnName} = '${item.value}'\n`;
      } else if (item.type === 'end') {
        result += `ENDDO \n`;
      }
    });
    setStatementString(result)
  }

  const updateTemplateId = (templateId) => {
    let checkId = templateIds?.some((item) => item.templateId === templateId);
    if (checkId) {
      setTemplateIds((preDerivation) =>
        preDerivation.map((item) =>
          item.templateId === templateId
            ? {
              ...item,
              templateId: templateId,
            }
            : item
        )
      );
    } else {
      if (templateId) {
        setTemplateIds((prevItems) => [
          ...prevItems,
          { templateId: templateId },
        ]);
      }
    }
  };

  useEffect(() => {
    setPrimaryDataType(derivationData?.primaryDataType);
    setPrimaryDataset(derivationData?.primaryDataset);
    if (type === "Sort") {
      setFormSections(derivationData?.sortData);
    } else {
      setIdVar(derivationData?.idVar);
      setByVar(derivationData?.byVar);
      setVars(derivationData?.vars);
      setPrefix(derivationData?.prefix);
      setSuffix(derivationData?.suffix);
    }
    setLabel(derivationData?.label);
    getDatasetColumList(
      derivationData?.primaryDataset,
      derivationData?.primaryDataType
    );
    setStatements(derivationData?.statements)
  }, [derivationData]);

  useEffect(() => {
    if (statements?.length > 0) {
      conditionString()
    }
  }, [statements]);

  const addNewStatement = (type) => {
    const newObject = {
      id: Math.random().toString().substr(2, 10),
      type: type,
    };

    if (type === "if" || type === "elseif") {
      newObject.keyType = "";
      newObject.keys = [];
      newObject.key = "";
      newObject.operator = "";
      newObject.value = "";
    }

    if (type === "else" || type === "then") {
      newObject.columnType = "";
      newObject.columnName = "";
      newObject.value = "";
    }

    setStatements((prevItems) => [...prevItems, newObject]);
  };

  const handleStatementChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const handleMultiSelectChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const removeStatement = (id) => {
    let newArray = statements?.filter((item) => item?.id !== id);
    setStatements(newArray);
  };

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 550 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Configuration</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto py-2 px-3"
            style={{ height: "calc(100vh - 87px" }}
          >
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 fw-bold">
                Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </Form.Group>
            <div
              className="py-2 px-3 mb-3 rounded position-relative"
              style={{ border: "1px dashed #dee2e6" }}
            >
              <Form.Label
                className="m-0 fw-bold position-absolute"
                style={{ top: -12, left: 10 }}
              >
                Dataset Configuration
              </Form.Label>
              <Form.Group className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Primary Dataset <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="mb-1"
                  onChange={(e) => setPrimaryDataType(e.target.value)}
                >
                  <option value="">Select Dataset Type</option>
                  <option selected={primaryDataType === "crf"} value="crf">
                    CRF Datasets
                  </option>
                  <option
                    selected={primaryDataType === "derivation"}
                    value="derivation"
                  >
                    Derivation Datasets
                  </option>
                </Form.Select>
                {primaryDataType === "crf" && (
                  <Form.Select
                    onChange={(e) => {
                      getDatasetColumList(e.target.value);
                      updateTemplateId(e.target.value);
                    }}
                    className="mb-1"
                  >
                    <option value="">Select CRF Data</option>
                    {crfDatasets?.map((item, index) => (
                      <option
                        selected={primaryDataset === item.uniqueId}
                        key={index}
                        value={item?.uniqueId}
                      >
                        {item.datasetName}
                      </option>
                    ))}
                  </Form.Select>
                )}
                {primaryDataType === "derivation" && (
                  <Form.Select
                    onChange={(e) => {
                      getDatasetColumList(e.target.value);
                      updateTemplateId(e.target.value);
                    }}
                    className="mb-1"
                  >
                    <option value="">Select Derivation Data</option>
                    {derivedData?.map(
                      (item, index) =>
                        item.logStatus === 200 && (
                          <option
                            selected={primaryDataset === item.id}
                            key={index}
                            value={item?.id}
                          >
                            {item.label}
                          </option>
                        )
                    )}
                  </Form.Select>
                )}
              </Form.Group>
              {type === "Sort" ?
                <>
                  <Form.Group className="mb-2">
                    <div className="hstack gap-2">
                      <Form.Label className="mb-1 fw-bold">
                        Sort By <span className="text-danger">*</span>
                      </Form.Label>
                      <FaSquarePlus
                        className="cursor-pointer"
                        style={{ fontSize: 12 }}
                        title="Add More"
                        onClick={handleAddSection}
                      />
                    </div>
                  </Form.Group>
                  {formSections?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          onChange={(e) =>
                            handleSelectChange(section.id, "column", e.target.value)
                          }
                        >
                          <option value="">Select Column</option>
                          {columns?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          value={section.sortType}
                          onChange={(e) =>
                            handleSelectChange(
                              section.id,
                              "sortType",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Sort type</option>
                          <option
                            selected={section?.sortType === "ascending"}
                            value="ascending"
                          >
                            Sort Ascending
                          </option>
                          <option
                            selected={section?.sortType === "descending"}
                            value="descending"
                          >
                            Sort Descending
                          </option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        {formSections.length > 1 && (
                          <FaTrashAlt
                            className="text-danger fontSize12 cursor-pointer"
                            title="Remove Section"
                            onClick={() => handleRemoveSection(section.id)}
                          />
                        )}
                      </Form.Group>
                    </div>
                  ))}
                </>
                :
                <>
                  <Form.Group className="mb-2">
                    <div className="hstack gap-2">
                      <Form.Label className="mb-1 fw-bold">
                        Transpose Settings <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label className="mb-1 fw-bold">
                        Prefix <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Prefix"
                        value={prefix}
                        onChange={(e) => setPrefix(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label className="mb-1 fw-bold">
                        Suffix <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Suffix"
                        value={suffix}
                        onChange={(e) => setSuffix(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-2">
                    <div className="hstack gap-2">
                      <Form.Label className="mb-1 fw-bold">
                        Id Var <span className="text-danger">*</span>
                      </Form.Label>
                      <FaSquarePlus
                        className="cursor-pointer"
                        style={{ fontSize: 12 }}
                        title="Add More"
                        onClick={handleAddSection}
                      />
                    </div>
                  </Form.Group>
                  {idVar?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          onChange={(e) =>
                            handleSelectChange(section.id, "column", e.target.value)
                          }
                        >
                          <option value="">Select Column</option>
                          {columns?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        {idVar.length > 1 && (
                          <FaTrashAlt
                            className="text-danger fontSize12 cursor-pointer"
                            title="Remove Section"
                            onClick={() => handleRemoveSection(section.id)}
                          />
                        )}
                      </Form.Group>
                    </div>
                  ))}

                  <Form.Group className="mb-2">
                    <div className="hstack gap-2">
                      <Form.Label className="mb-1 fw-bold">
                        By Var <span className="text-danger">*</span>
                      </Form.Label>
                      <FaSquarePlus
                        className="cursor-pointer"
                        style={{ fontSize: 12 }}
                        title="Add More"
                        onClick={handleByVarAddSection}
                      />
                    </div>
                  </Form.Group>
                  {byVar?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          onChange={(e) =>
                            handleByVarSelectChange(section.id, "column", e.target.value)
                          }
                        >
                          <option value="">Select Column</option>
                          {columns?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        {byVar.length > 1 && (
                          <FaTrashAlt
                            className="text-danger fontSize12 cursor-pointer"
                            title="Remove Section"
                            onClick={() => handleByVarRemoveSection(section.id)}
                          />
                        )}
                      </Form.Group>
                    </div>
                  ))}

                  <Form.Group className="mb-2">
                    <div className="hstack gap-2">
                      <Form.Label className="mb-1 fw-bold">
                        Var <span className="text-danger">*</span>
                      </Form.Label>
                      <FaSquarePlus
                        className="cursor-pointer"
                        style={{ fontSize: 12 }}
                        title="Add More"
                        onClick={handleVarsAddSection}
                      />
                    </div>
                  </Form.Group>
                  {vars?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          onChange={(e) =>
                            handleVarsSelectChange(section.id, "column", e.target.value)
                          }
                        >
                          <option value="">Select Column</option>
                          {columns?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        {vars.length > 1 && (
                          <FaTrashAlt
                            className="text-danger fontSize12 cursor-pointer"
                            title="Remove Section"
                            onClick={() => handleVarsRemoveSection(section.id)}
                          />
                        )}
                      </Form.Group>
                    </div>
                  ))}
                </>
              }
            </div>
            <Form.Label
              onClick={conditionString}
              className="mb-2 fw-bold"
            >
              <div className="hstack gap-2">
                Statement (Optional)
                <Form.Check
                  onChange={() => setEditStatement(!editStatement)}
                  checked={editStatement}
                  type="switch"
                  id="custom-switch"
                />
              </div>
            </Form.Label>
            {!editStatement ?
              statementString &&
              <SyntaxHighlighter language="javascript" style={dracula}>
                {statementString}
              </SyntaxHighlighter>
              :
              <div
                className="p-3 rounded"
                style={{ border: "1px dashed #dee2e6" }}
              >
                <div className="hstack gap-2 mb-2">
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("if")}
                  >
                    IF
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("else")}
                  >
                    ELSE
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("elseif")}
                  >
                    ELSE IF
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("then")}
                  >
                    THEN DO
                  </button>
                  <button
                    className="updateProfile"
                    onClick={() => addNewStatement("end")}
                  >
                    END DO
                  </button>
                </div>
                {statements?.map((item, index) => (
                  <div key={index}>
                    <Collapse in={item?.type === "if"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            IF
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            onChange={(e) => handleStatementChange(item.id, 'keyType', e.target.value)}
                            className="mb-2">
                            <option value="">Select</option>
                            <option selected={item?.keyType === "firstKey"} value="firstKey">First Key</option>
                            <option selected={item?.keyType === "lastKey"} value="lastKey">Last Key</option>
                            <option selected={item?.keyType === "column"} value="column">Column</option>
                          </Form.Select>
                          {item?.keyType !== "column" &&
                            <Multiselect
                              displayValue="label"
                              options={columns?.map((keys, key) => ({
                                label: keys?.name,
                                value: keys?.name,
                              }))}
                              selectedValues={item.keys || []}
                              onSelect={(selectedList) => handleMultiSelectChange(item.id, 'keys', selectedList)}
                              onRemove={(removedList) => handleMultiSelectChange(item.id, 'keys', removedList)}
                            />
                          }
                          {item?.keyType === "column" &&
                            <>
                              <Form.Select
                                onChange={(e) => handleStatementChange(item.id, 'key', e.target.value)}
                                className="mb-2">
                                <option value={""}>{`Select Column`}</option>
                                {columns?.map((data, index) => (
                                  <option selected={item?.key === data?.name} key={index} value={data?.name}>{data?.name}</option>
                                ))}
                              </Form.Select>
                              <div className="my-2">
                                <InputGroup className="justify-content-center">
                                  <InputGroup.Text
                                    className={`cursor-pointer updateProfile ${item.operator === "AND" && "bg-white text-muted"}`}
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'AND')}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "3px 0 0 3px",
                                    }}
                                  >
                                    AND
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'OR')}
                                    className={`cursor-pointer updateProfile ${item.operator === "OR" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    OR
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '+')}
                                    className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    +
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '-')}
                                    className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    -
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '/')}
                                    className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    /
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '*')}
                                    className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    *
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '%')}
                                    className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    %
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    =
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !=
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '>')}
                                    className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=>')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=>" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    IN
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !IN
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <Form.Control
                                onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)}
                                type="text"
                                value={item.value}
                                placeholder="Enter Value"
                              />
                            </>
                          }
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "elseif"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            ELSEIF
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            onChange={(e) => handleStatementChange(item.id, 'keyType', e.target.value)}
                            className="mb-2">
                            <option value="">Select</option>
                            <option selected={item?.keyType === "firstKey"} value="firstKey">First Key</option>
                            <option selected={item?.keyType === "lastKey"} value="lastKey">Last Key</option>
                            <option selected={item?.keyType === "column"} value="column">Column</option>
                          </Form.Select>
                          {item?.keyType !== "column" &&
                            <Multiselect
                              displayValue="label"
                              options={columns?.map((keys, key) => ({
                                label: keys?.name,
                                value: keys?.name,
                              }))}
                              selectedValues={item.keys || []}
                              onSelect={(selectedList) => handleMultiSelectChange(item.id, 'keys', selectedList)}
                              onRemove={(removedList) => handleMultiSelectChange(item.id, 'keys', removedList)}
                            />
                          }
                          {item?.keyType === "column" &&
                            <>
                              <Form.Select
                                onChange={(e) => handleStatementChange(item.id, 'key', e.target.value)}
                                className="mb-2">
                                <option value={""}>{`Select Column`}</option>
                                {columns?.map((data, index) => (
                                  <option selected={item?.key === data?.name} key={index} value={data?.name}>{data?.name}</option>
                                ))}
                              </Form.Select>
                              <div className="my-2">
                                <InputGroup className="justify-content-center">
                                  <InputGroup.Text
                                    className={`cursor-pointer updateProfile ${item.operator === "AND" && "bg-white text-muted"}`}
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'AND')}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "3px 0 0 3px",
                                    }}
                                  >
                                    AND
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'OR')}
                                    className={`cursor-pointer updateProfile ${item.operator === "OR" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    OR
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '+')}
                                    className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    +
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '-')}
                                    className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    -
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '/')}
                                    className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    /
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '*')}
                                    className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    *
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '%')}
                                    className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"}`}
                                    style={{ padding: "5px 9.7px", fontSize: 10 }}
                                  >
                                    %
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    =
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!=')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!=" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !=
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '>')}
                                    className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=<')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=<" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=<`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '=>')}
                                    className={`cursor-pointer updateProfile ${item.operator === "=>" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    {`=>`}
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', 'IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    IN
                                  </InputGroup.Text>
                                  <InputGroup.Text
                                    onClick={() => handleMultiSelectChange(item.id, 'operator', '!IN')}
                                    className={`cursor-pointer updateProfile ${item.operator === "!IN" && "bg-white text-muted"}`}
                                    style={{
                                      padding: "5px 9.7px",
                                      fontSize: 10,
                                      borderRadius: "0 3px 3px 0",
                                    }}
                                  >
                                    !IN
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <Form.Control value={item.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                            </>
                          }
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "then"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            THEN DO
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            className="mb-1"
                            onChange={(e) => handleStatementChange(item.id, 'columnType', e.target.value)}
                          >
                            <option value="">Select Coumn Type</option>
                            <option selected={item?.columnType === "dataset"} value="dataset">From Dataset</option>
                            <option selected={item?.columnType === "custom"} value="custom">Custom</option>
                          </Form.Select>
                          {item.columnType === "dataset" &&
                            <Form.Select
                              className="mb-1"
                              onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)}
                            >
                              <option value="">Select Coumn</option>
                              {columns?.map((data, index) => (
                                <option selected={item?.columnName === data?.name} key={index} value={data?.name}>{data?.name}</option>
                              ))}
                            </Form.Select>
                          }
                          {item.columnType === "custom" &&
                            <Form.Control value={item?.columnName} type="text" onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)} placeholder="Enter Custom Column Name" />
                          }
                          <div className=" my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: 3,
                                }}
                              >
                                =
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control value={item?.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "else"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            ELSE
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                        <div className="p-3">
                          <Form.Select
                            className="mb-1"
                            onChange={(e) => handleStatementChange(item.id, 'columnType', e.target.value)}
                          >
                            <option value="">Select Coumn Type</option>
                            <option selected={item?.columnType === 'dataset'} value="dataset">From Dataset</option>
                            <option selected={item?.columnType === 'custom'} value="custom">Custom</option>
                          </Form.Select>
                          {item.columnType === "dataset" &&
                            <Form.Select
                              className="mb-1"
                              onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)}
                            >
                              <option value="">Select Coumn</option>
                              {columns?.map((data, index) => (
                                <option selected={item?.columnName === data?.name} key={index} value={data?.name}>{data?.name}</option>
                              ))}
                            </Form.Select>
                          }
                          {item.columnType === "custom" &&
                            <Form.Control value={item?.columnName} type="text" onChange={(e) => handleStatementChange(item.id, 'columnName', e.target.value)} placeholder="Enter Custom Column Name" />
                          }
                          <div className=" my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: 3,
                                }}
                              >
                                =
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control value={item?.value} onChange={(e) => handleStatementChange(item.id, 'value', e.target.value)} type="text" placeholder="Enter Value" />
                        </div>
                      </div>
                    </Collapse>
                    <Collapse in={item?.type === "end"}>
                      <div className="position-relative shadow-sm border mb-3">
                        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                          <Form.Label
                            className="mb-0 fw-bold"
                            style={{ minWidth: 40 }}
                          >
                            END DO
                          </Form.Label>
                          <FaTrashCan
                            onClick={() => removeStatement(item?.id)}
                            style={{ fontSize: 12 }}
                            className="CP text-danger"
                            title="Remove Statement"
                          />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="shadow-sm bg-light text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SortTransposeTools;
